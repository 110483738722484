
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'

export default defineComponent({
  name: 'NotFound',
  components: {
    PageHeader
  },
  mounted() {
    const w = window as any;
    w.fbq('track', 'ViewContent');
  }
});
